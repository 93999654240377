import PropTypes from 'prop-types';

export const DiscountImageType = PropTypes.shape({
    standard: PropTypes.string,
    sm: PropTypes.string,
    xs: PropTypes.string
});

export const BlackFridayCounterType = PropTypes.shape({
    discount_code: PropTypes.string,
    discount_image: DiscountImageType,
    time_start: PropTypes.string,
    time_end: PropTypes.string,
    items_total: PropTypes.number,
    items_remaining: PropTypes.number,
    cms_block: PropTypes.string,
    is_closed: PropTypes.bool,
    closed_image: PropTypes.string

});
