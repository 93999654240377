import { connect } from 'react-redux';

import { showPopup } from 'Store/Popup/Popup.action';

import InfoIcon from './InfoIcon.component';

/** @namespace Pwa/Component/InfoIcon/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({
    // wishlistItems: state.WishlistReducer.productsInWishlist
});

/** @namespace Pwa/Component/InfoIcon/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch, props) => ({
    showPopup: (payload) => dispatch(showPopup(props.id, payload))

});

export default connect(mapStateToProps, mapDispatchToProps)(InfoIcon);
