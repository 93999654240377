import PropTypes from 'prop-types';
import React, { } from 'react';
import CmsBlock from 'Component/CmsBlock/CmsBlock.component';
import Loader from 'Component/Loader';
import {
    CmsBlockContainer as SourceCmsBlockContainer
} from 'SourceComponent/CmsBlock/CmsBlock.container';
import Swiper from 'swiper';
import 'swiper/swiper.scss';

/** @namespace Pwa/Component/CmsBlock/Container/CmsBlockContainer */
export class CmsBlockContainer extends SourceCmsBlockContainer {
    static propTypes = {
        ...SourceCmsBlockContainer.propTypes,
        onLoad: PropTypes.func,
        showLoader: PropTypes.bool,
        placeHolderSize: PropTypes.shape({
            width: PropTypes.string,
            height: PropTypes.string
        })
    };

    static defaultProps = {
        onLoad: (_identifier) => { },
        showLoader: false,
        placeHolderSize: {
            width: '',
            height: ''
        }
    };

    componentDidMount() {
        this._getCmsBlock();

        new Swiper('.slider-cat-items', {
            slidesPerView: 4.2,
            spaceBetween: 30,
            loop: true,
            navigation: {
                nextEl: ".slider-cat-next",
                prevEl: ".slider-cat-prev",
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                },
                480: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                },
                768: {
                  slidesPerView: 2.5,
                  spaceBetween: 30,
                },
                1024: {
                  slidesPerView: 3.5,
                  spaceBetween: 30,
                },
                1366: {
                  slidesPerView: 4.2,
                  spaceBetween: 30,
                },
            },
        })
    }

    componentDidUpdate(oldProps) {
        const { identifier: old_identifier } = oldProps;
        const { identifier: new_identifier, onLoad } = this.props;
        if (old_identifier !== new_identifier) {
            this._getCmsBlock();
            onLoad(new_identifier);
        }
    }

    render() {
        const { showLoader, placeHolderSize } = this.props;
        const { cmsBlock } = this.state;

        if (!cmsBlock.identifier) {
            if (placeHolderSize.width || placeHolderSize.height) {
                return <div style={placeHolderSize} className="CmsBlock-Loader" />;
            }

            if (showLoader) {
                return <Loader isLoading />;
            }
        }

        return (
            <CmsBlock
                {...this.containerProps()}
            />
        );
    }
}

export default CmsBlockContainer;
