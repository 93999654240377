import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { countdown } from 'Util/Countdown';

import PromoMask from './PromoMask.component';

/** @namespace Pwa/Component/PromoMask/Container/PromoMaskContainer */
export class PromoMaskContainer extends PureComponent {
    static propTypes = {
        reveal_time: PropTypes.string.isRequired
    };

    containerFunctions = {
        // getData: this.getData.bind(this)
    };

    state = {
        countdown: {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0
        }
    };

    componentDidMount() {
        const { reveal_time } = this.props;
        this.carouselInterval = countdown(reveal_time, (countdown) => this.setState({ countdown }));
    }

    componentWillUnmount() {
        clearTimeout(this.carouselInterval);
    }

    containerProps = () => {
        const { countdown } = this.state;
        return {
            countdown
        };
    };

    render() {
        return (
            <PromoMask
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default PromoMaskContainer;
