/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './InfoIcon.style';

/** @namespace Pwa/Component/InfoIcon/Component/InfoIconComponent */
export class InfoIconComponent extends PureComponent {
    static propTypes = {
        showPopup: PropTypes.func.isRequired,
        id: PropTypes.string.isRequired
    };

    render() {
        const { id, showPopup } = this.props;

        return (
            <span
              key={ id }
              block="InfoIcon"
              role="button"
              tabIndex="0"
              onClick={ showPopup }
            >
                <i
                  className="fa fa-info-circle"
                  aria-hidden
                />
            </span>

        );
    }
}

export default InfoIconComponent;
