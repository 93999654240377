/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable max-lines */

import {
    CmsBlockComponent as SourceCmsBlock
} from 'SourceComponent/CmsBlock/CmsBlock.component';

import './CmsBlock.override.style';

/** @namespace Pwa/Component/CmsBlock/Component/CmsBlockComponent */
export class CmsBlockComponent extends SourceCmsBlock {

}

export default CmsBlockComponent;
