export const ONE_DAY = 86400;
export const ONE_HOUR = 3600;
export const ONE_MINUTE = 60;

export const CENTO = 100;

export const PRICE = 200;
export const BLACK_FRIDAY_PRICE = 99;
export const ITEMS_REMAINING = 10;
export const ITEMS_TOTAL = 10;
