import PropTypes from 'prop-types';

export const ImageStickersType = PropTypes.shape({
    position: PropTypes.oneOf([
        'TOPLEFT',
        'TOPRIGHT',
        'BOTTOMLEFT',
        'BOTTOMRIGHT'
    ]),
    cms_block: PropTypes.string
});
